@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&family=Raleway:wght@200;300;400;700&display=swap);
:root {
  --turchese: #C8E0E8;
  --pesca: #F7B6B6;
  --verde: #D8EACC;
  --giallo: #F6DA8D;
  --azzurro: #13EBF6;
  --blue: #024289;
  --gray2: #555;
  --white: #FFF;
}

/* font-family: 'Fredoka One', cursive;
font-family: 'Raleway', sans-serif; */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiCardHeader-avatar {
  margin-right: 0!important;
}

.loaderContainer {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  flex-direction: column;
}

.loaderContainer h3 {
  text-align: center;
  clear: both;
}

.loaderContainer img {
  width: 10vw;
  height: 10vw;
  -webkit-transform-origin: 50% 80%;
  -webkit-transform: rotate(-15deg);
  transform-origin: 50% 80%;
  transform: rotate(-15deg);
  animation: splash-logo 1.5s ease-out infinite;
}

.logoLoader {
  -webkit-transform-origin: 50% 80%;
  -webkit-transform: scale(0);
  transform-origin: 50% 80%;
  transform: scale(0);
  animation: splash-logo 0.3s ease-out;
}

.icona {
  background: var(--blue);
}

@keyframes splash-logo {
  0% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: scale(0);
    transform-origin: 50% 80%;
    transform: scale(0);
  }
  100% {
    -webkit-transform-origin: 50% 80%;
    -webkit-transform: scale(1);
    transform-origin: 50% 80%;
    transform: scale(1);
  }
}

/*===========NEW STYLE===========*/

/*===========GENERAL===========*/

.filterRoot {
  margin: 30px 0!important;
  background: #fafafa;
  border-radius: 0px;
  /* position: -webkit-sticky;
  position: sticky!important; */
  top: 0;
  z-index: 100;
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.searchContainer {
  position: relative!important;
}

.searchField {
  margin-top: 15px!important;
  margin-left: 0!important;
  width: 100%;
}

.hideLogo {
  display: none;
}

.searchIcon {
  position: absolute;
  right: 0;
  top: 15px;
}

.filterRoot .MuiPaper-elevation1 {
  box-shadow: none!important;
  background-color: transparent!important;
}

.cardsHome {
  min-width: 19%;
}

.filterRoot>div {
  margin-bottom: 20px;
}

.buttonUpload {
  background-color: var(--blue)!important;
  color: white!important;
  padding: 15px!important;
}

.filterRoot>div .MuiPaper-root {
  padding: 0;
}

.userIcon {
  opacity: 1;
  transition: all 0.2s ease-out!important;
  box-shadow: none!important;
}

.fade-out {
  opacity: 0;
  transition: all 0.2s ease-out!important;
}

/* h1,h2,.MuiListItemText-root .MuiTypography-root,.snippetCard h5, #root h5, .siteName, h5{
  font-family: 'Fredoka One', cursive!important;
} */

h5 {
  color: var(--blue)!important;
  font-size: 1.3rem!important;
  width: 100%;
  margin-bottom: 10px!important;
}

.MuiListItemText-root {
  color: var(--gray2);
}

.MuiListItem-root.Mui-selected .MuiListItemText-root {
  color: white;
}

p, ul, li, h6, td, th, input, textarea, span.MuiTab-wrapper, small {
  font-family: 'Raleway', sans-serif!important;
}

.logoLoaderContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logoLoaderContainer h3 {
  font-family: 'Raleway', sans-serif!important;
  color: var(--blue);
  font-weight: normal;
}

th {
  font-weight: bold!important;
}

.sourceLogo {
  margin: 0;
  border: 5px solid #fafafa;
}

.snippetCard {
  padding: 15px!important;
  border-radius: 10px;
  position: relative;
}

.boxContainer a>div {
  border-radius: 0px;
}

.innerCard {
  overflow: hidden;
  position: relative;
}

.bottomCard {
  width: 100%;
  border-bottom-left-radius: 10px;
}

.editIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  cursor: pointer;
  color: #888;
}

.borderFrame {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.MuiCardActions-spacing .MuiIconButton-label {
  color: var(--blue);
}

.avatarPl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.MuiButton-textPrimary {
  color: #888!important;
}

.MuiButton-textSecondary {
  color: black!important;
  font-weight: bold!important;
}

.modalSidebar {
  min-height: 50vh;
}

/*----------Modal----------*/

.MuiDialogTitle-root {
  background-color: var(--blue);
  padding: 16px 24px!important;
}

.MuiDialogTitle-root h2 {
  color: var(--white);
  font-size: 1.5rem;
}

.MuiDialogTitle-root button {
  color: #CACACA;
}

.MuiTabs-indicator {
  background-color: var(--blue);
}

.changePassword .MuiDialogTitle-root h2 {
  color: white!important;
}

/*---------New Modal-------*/

.containerNoPad {
  padding-left: 0!important;
  padding-right: 0!important;
}

.modalSidebar {
  flex: 1 1;
  border-right: 2px solid #eaeaea;
  /* min-height: 60vh; */
}

.modalSidebar h3 {
  /* font-family: 'Fredoka One', cursive!important; */
  margin-left: 15px;
  color: var(--blue);
}

.modalTab button {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  max-width: 100%;
  border-radius: 0;
}

.modalTab button:first-child {
  border-top: 1px solid #eaeaea;
}

.sidebarActions button {
  width: 100%;
  border-top: 1px solid #eaeaea;
  max-width: 100%;
  border-radius: 0;
}

.MuiDialog-paper {
  border-radius: 3px!important;
}

.modalContent {
  flex: 1 1;
  min-height: 75vh;
  max-height: 75vh;
}

.facilityModal .modalContent {
  min-height: auto;
  max-height: auto;
}

.innerModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

label {
  color: var(--gray2)!important;
  /* font-family: 'Fredoka One', cursive!important; */
  font-size: 1.2rem;
  font-weight: bolder;
}

.tabContent {
  padding: 0!important;
  margin: 20px 0 50px 0;
}

.socialSection {
  margin-top: 20px!important;
}

.actionContainer {
  padding: 10px;
  border-top: 2px solid #eaeaea;
}

.desktopTab .MuiTab-wrapper {
  align-items: flex-start;
}

/* .facilityModalInner{
  position:relative;
} */

.sidebarActions .MuiIconButton-label {
  justify-content: flex-start;
}

.allergenesSwitch {
  width: 50px;
  height: 50px;
}

.allergenesSwitch label {
  margin: 0!important;
}

.tabContent.allergenes .MuiToolbar-root {
  display: block;
  width: 100%!important;
  margin-bottom: 30px;
}

.tabContent.allergenes h6 {
  margin: 20px 0;
}

.tabContent.allergenes>div>div div:nth-child(3) {
  padding: 0!important;
}

.tabContent.allergenes .innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabContent.allergenes .innerContainer p {
  font-size: 14px;
  text-align: left;
}

.tabContent.allergenes>div>div div:nth-child(4) {
  float: right;
  max-width: 40%;
  margin-top: -20px;
}

.tabContent.allergenes>div>div div:nth-child(4) .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.0)!important;
}

.tabContent.allergenes>div>div div:nth-child(4) .MuiIconButton-root .MuiTouchRipple-root {
  opacity: 0!important;
}

.accordionScroll {
  max-height: 350px;
  overflow-y: scroll;
  padding-top: 0!important;
  flex-direction: column;
  padding: 15px 0;
  align-items: flex-start;
}

.newLot .accordionScroll {
  max-height: 100%!important;
  overflow-y: scroll;
  padding-top: 0!important;
}

.lotImg {
  width: 100%;
  height: 200px;
}

.accordionScroll .MuiChip-root {
  margin: 5px 0;
  padding: 10px;
}

.MuiAccordion-root {
  box-shadow: none!important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0!important;
  min-height: 48px!important;
}

.productModal .MuiAccordionSummary-content.Mui-expanded {
  margin: 0!important;
}

.productModalInner, .companyModalInner {
  overflow-y: hidden!important;
}

.MuiAccordion-root:before {
  height: 0;
}

.MuiAccordionSummary-root {
  padding: 6px 12px!important;
}

.MuiAccordionSummary-content p {
  text-transform: uppercase;
  font-size: 0.875rem!important;
}

.tabPhases .MuiTab-wrapper {
  display: flex!important;
  flex-direction: row-reverse!important;
  justify-content: space-between!important;
  align-items: center!important;
}

.documents .MuiPaper-elevation2 {
  width: 100%;
}

.documentActions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.documentActions svg {
  margin-left: 15px;
}

.accordionScroll #chipContainer {
  background-color: white!important;
  flex-direction: column!important;
}

/*----------Help Modal----------*/

.helpModal .MuiDialogTitle-root h2 {
  color: white;
}

.helpModal .MuiTabs-indicator {
  background-color: var(--blue)!important;
}

.addDocumentTable table thead tr th:nth-child(4), .addDocumentTable table tr td:nth-child(4) {
  text-align: center!important;
}

.addDocumentTable table thead tr th:nth-child(4) span {
  justify-content: center;
}

/*----------Form----------*/

.MuiInputBase-input {
  padding: 10px!important;
  padding-left: 0!important;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom: 3px solid #EAEAEA!important;
  margin-top: 5px;
}

.MuiInput-root {
  font-family: 'Raleway', sans-serif!important;
}

.MuiInput-underline:before {
  border-bottom: 3px solid #AEAEAE!important;
}

.MuiInput-underline:after {
  border-bottom: 3px solid var(--blue)!important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--blue)!important;
}

.customSidebar ul.MuiList-root .MuiSvgIcon-root {
  margin-left: 9px;
}

.customSidebar {
  background-color: #fafafa!important;
}

/*===========SIGN IN | SIGN UP===========*/

.signContainer {
  padding: 15px;
  /* background-color: #FAFAFA!important; */
  border-radius: 10px;
}

.signContainer a, .signContainer a:link, .signContainer a:active, .signContainer a:visited {
  color: var(--blue);
}

.signContainer a:hover {
  color: var(--azzurro);
}

.signContainer input {
  text-align: center;
}

.signInHeader {
  flex-direction: column;
  text-align: center;
  margin: 0;
}

.signContainer * {
  font-family: 'Raleway', sans-serif!important;
}

.signContainer .MuiCardContent-root {
  margin: 25px 0;
}

.brand {
  max-height: 130px;
  margin-top: 20px;
}

.logoContainer {
  background-size: contain!important;
  max-height: 130px;
  margin-top: 20px;
}

.userIcon {
  background-color: var(--blue)!important;
}

.passwordReset .Dashboard-pageContainer-11 {
  height: calc(100vh - 48px);
}

.passwordReset .MuiGrid-container {
  height: 100%;
}

#chipContainer {
  padding: 10px 0!important;
}

.MuiAccordionDetails-root {
  padding: 0 10px!important;
}

/* -----------MODAL CREAZIONE------------*/

.fakeTab {
  padding: 0;
  margin: 0;
}

.fakeTab li {
  list-style: none;
  padding: 15px;
  text-transform: uppercase;
  cursor: not-allowed;
  margin: 0;
  border-bottom: 1px solid var(--verde);
  color: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newLot .fakeTab li {
  border-bottom: 1px solid #eaeaea;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leaflet-container {
  z-index: 0;
}

.filterTable>div>div {
  min-height: auto!important;
}

/* -----------FINE MODAL CREAZIONE------------*/

@media screen and (max-width:1470px) {
  .cardsHomeContainer {
    justify-content: flex-start!important;
  }
  .cardsHome {
    min-width: calc(33.3% - 20px);
    margin: 10px 20px 10px 0!important;
  }
}

@media screen and (max-width:1050px) {
  .cardsHomeContainer {
    justify-content: flex-start!important;
  }
  .cardsHome {
    min-width: calc(50% - 20px);
    margin: 10px 20px 10px 0!important;
  }
}

@media screen and (max-width:960px) {
  .Dashboard-content-13 {
    height: auto!important;
  }
  .customSidebar {
    border-right: 0px solid transparent!important;
  }
  .notificationButton {
    padding: 10px;
  }
  h3 {
    /* font-family: 'Fredoka One', cursive!important; */
    color: var(--blue);
  }
}

/*=========RESPONSIVE=========*/

@media screen and (max-width:960px) {
  .chartFilter {
    display: block!important;
  }
  .MuiDialog-container {
    height: 100vh!important;
  }
  .MuiDialog-container .MuiPaper-root.MuiPaper-elevation4 {
    width: 100%!important;
  }
  .chartFilter .MuiChip-root {
    margin-top: 20px;
  }
  .notificationBox {
    display: block!important;
  }
  .tabContent {
    padding: 15px 0!important;
    padding-bottom: 50px!important;
  }
  .Dashboard-pageContainer-11 {
    padding-bottom: 50px!important;
  }
  .lotModal .MuiChip-root {
    margin: 10px 0;
  }
  .lotsTable>div>div:first-child {
    display: block!important;
  }
  .lotsTable>div>div:first-child h6 {
    margin-top: 25px;
  }
  .lotsTable>div>div:first-child .MuiTextField-root {
    margin: 15px 0;
    padding: 0;
    width: 100%;
  }
  .formPl {
    padding-left: 10px;
  }
  .formPr {
    padding-right: 10px;
  }
  .MuiDialog-paper {
    border-radius: 0px!important;
  }
  .MuiChip-root {
    margin-right: 15px!important;
  }
  .modalContent {
    min-height: 78vh!important;
  }
  .mobileModal {
    width: 100%;
  }
}

@media screen and (max-width:600px) {
  .MuiDialogContent-root:first-child {
    margin-bottom: 100px!important;
  }
  .MuiDialog-container {
    position: relative!important;
  }
  .leaflet-container {
    margin-bottom: 150px;
  }
  .formPl {
    padding-left: 0px;
  }
  .mobileModal {
    max-width: 100%;
  }
  .facilityModalInner .tabContent {
    padding: 15px!important;
  }
  .formPr {
    padding-right: 0px;
  }
  .tabContent.allergenes .MuiTextField-root {
    width: 100%;
  }
  .tabContent.allergenes>div>div div:nth-child(4) {
    max-width: 100%;
    margin-top: 10px;
  }
  .tabContent.allergenes>.MuiPaper-root>div:nth-child(2) {
    width: 100%;
    margin-top: 10px;
  }
  .tabContent.allergenes>div>div div:nth-child(4) button {
    padding: 0!important;
  }
  #simple-tabpanel-3 .MuiToolbar-root {
    display: block!important;
  }
  #simple-tabpanel-3 .MuiToolbar-root .MuiFormControl-root {
    max-width: 100%;
    padding: 0!important;
    margin: 10px 0;
    width: 100%;
  }
  #simple-tabpanel-3 .MuiToolbar-root h6 {
    margin-top: 20px;
  }
  #simple-tabpanel-4 .MuiToolbar-root .MuiFormControl-root {
    max-width: 100%;
    padding: 0!important;
    margin: 10px 0;
    width: 100%;
  }
  .tabContent, .tabContent>div {
    padding: 0!important;
  }
  /* .mobileTab{
    width:100%;
    padding:10px;
    margin-top: 10px;
  } */
  .mobileTab .MuiBox-root {
    flex-direction: row;
  }
  .phases .MuiToolbar-root {
    display: block!important;
  }
  .phases .MuiToolbar-root .MuiFormControl-root {
    max-width: 100%;
    padding: 0!important;
    margin: 10px 0;
    width: 100%;
  }
  .phases .MuiToolbar-root h6 {
    margin-top: 20px;
  }
  .MuiDialogTitle-root h2 {
    font-size: 1.5rem;
  }
  .lotTable .MuiToolbar-root {
    display: block!important;
  }
  .lotTable .MuiToolbar-root .MuiFormControl-root {
    max-width: 100%;
    padding: 0!important;
    margin: 10px 0;
    width: 100%;
  }
  .lotTable .MuiToolbar-root h6 {
    margin-top: 20px;
  }
  .MuiChip-root {
    margin-right: 10px!important;
  }
  .documentActions svg {
    margin: 0 10px;
  }
  .addProduct {
    margin-left: 25px;
  }
  .MuiDialogActions-root {
    flex-direction: column;
  }
  .MuiDialogActions-root button {
    width: 100%;
    margin: 10px 0;
  }
  .modalContent {
    min-height: 61.5vh!important;
  }
  .MuiDialogActions-root button {
    padding: 15px 0;
    margin: 0;
  }
  .MuiDialogActions-root button:first-child {
    border-bottom: 1px solid #eaeaea;
  }
  .lotModalInner .MuiDialogContent-root {
    overflow-x: hidden;
    max-width: 100%;
  }
  .mobileModal .MuiDialogTitle-root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
  .mobileModal .MuiDialogContent-root {
    margin-top: 70px;
  }
  .lotModalInner .mobileModal .MuiPaper-root {
    left: 0;
    z-index: 10;
    width: 100%;
  }
  .lotModalInner .mobileModal {
    margin-top: 100px;
  }
  .companyModalInner .modalContent {
    overflow-y: scroll;
  }
  .mainActions {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 1000,
  }
  .facilityModalInner .MuiDialogTitle-root {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
  }
  .facilityModalInner .modalContent {
    margin-top: 70px;
  }
  .lotModalInner .MuiDialogContent-root {
    margin-top: 0!important;
  }
  .productModalInner .modalContent {
    overflow-y: scroll!important;
  }
  .companyModalInner .MuiDialogActions-root {
    position: fixed;
    width: 100%;
    background-color: white;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}

@media screen and (max-width:560px) {
  .cardsHome {
    min-width: 100%;
    margin: 10px 0!important;
  }
}
:root {
    --smallW:30px;
    --smallH:30px;
    --mediumW:50px;
    --mediumH:50px;
    --largeW:180px;
    --largeH:180px;
    --dark:black;
    --light:white;
}

.small{
    width: var(--smallW);
    height: var(--smallH);
}

.medium{
    width: var(--mediumW);
    height: var(--mediumH);
}

.large{
    width: var(--largeW);
    height: var(--largeH);
}

.dark{
    color: var(--dark);
}

.light{
    color: var(--light);
}
.app-class {
  padding: 0 !important;
}

#root {
  overflow: hidden;
}

.test>div{
  height:100vh;
}
