:root {
    --smallW:30px;
    --smallH:30px;
    --mediumW:50px;
    --mediumH:50px;
    --largeW:180px;
    --largeH:180px;
    --dark:black;
    --light:white;
}

.small{
    width: var(--smallW);
    height: var(--smallH);
}

.medium{
    width: var(--mediumW);
    height: var(--mediumH);
}

.large{
    width: var(--largeW);
    height: var(--largeH);
}

.dark{
    color: var(--dark);
}

.light{
    color: var(--light);
}